import React, { Fragment } from 'react';
import LoginButton from './util/LoginButton';
import { ReactComponent as DownIcon } from '../icons/arrowDown.svg';
import { ReactComponent as ReadListImage } from '../icons/readlist_r_hero.svg';


export default function Landing(props){
	 const scrollForMore = () => {
		const el = document.getElementById("more");
		el.scrollIntoView({
			behavior: 'smooth',
			block: 'start'
		});
	}

	return(
	<Fragment>
		<div className='landHero'>
			<div className='landHero__container'>
				<nav className='landHero__nav'>
					<div className='landHero__nav__logo'>
						<a href='/' className='landHero__nav__logo__link'>readlist</a>
					</div>
					<div className='landHero__nav__menu'>
						<span className='landHero__nav__menu__item' onClick={() => { scrollForMore() }}>learn more</span>
						<a href="https://twitter.com/ReadlistCom" target="_blank" rel='noopener noreferrer' className='landHero__nav__menu__item'>twitter</a>
						<a href="https://twitter.com/anges244" target="_blank" rel='noopener noreferrer' className='landHero__nav__menu__item'>contact</a>
					</div>
				</nav>
				<div className='landHero__main'>
					<div className='landHero__content'>
						<div className='landHero__tablet'></div>
						<div className='landHero__text'>
							<h1 className='landHero__text__title'>Every link you find interesting,<br/>
							<span className='landHero__stress'>ready and waiting</span> for you.</h1>
							<LoginButton handleLogin={props.handleLogin} cssClass='landHero__cta' />
						</div>
						<div className='landHero__footer'>
							<div className='landHero__footer__content'>
								<DownIcon className='landHero__more' onClick={() => { scrollForMore() }} />
							</div>
							<div className='landHero__mobMedia'>
								<div className='landHero__mobMedia__image'>
									<ReadListImage className='landHero__mobMedia__image__item'/>
								</div>
							</div>
						</div>
					</div>
					<div className='landHero__media'>
						<div className='landHero__media__image'>
							<ReadListImage className='landHero__media__image__item' />
						</div>
					</div>
				</div>
			</div>
		</div>

		<div className='lbenefits' id='more'>
			<div className='lbenefits__container'>
				<div className='lbenefits__title'>
					<h2>Get your attention back. <br />No more scattered bookmarks.</h2>
				</div>
				<div className='lbenefits__content'>
					<p>Add every link you like automatically, from your favorite messaging or social app.</p>
					<p>Read content you find interesting when you want, not while you are in a browsing mode.</p>
					<p>Keep only what has value for you, in your history, to revisit if you ever want.</p>
				</div>
			</div>
		</div>

		<div className='lfeatures'>
			<div className='lfeatures__container'>
				<div className='lfeatures__left'>
					<p>No load more distractions</p>
				</div>
				<div className='lfeatures__center'>
					<p className='lfeatures__mob'>Deep Twitter Integration</p>
					<p>Chronological feed</p>
					<h2>Designed to work. <br />No distractions.</h2>
					<p>Selective history</p>
					<p className='lfeatures__mob'>No load more distractions</p>
				</div>
				<div className='lfeatures__right'>
					<p>Deep Twitter Integration</p>
				</div>
			</div>
		</div>

		<div className='lcta'>
			<div className='lcta__container'>
				<h2 className='lcta__title'>Get started right away</h2>
				<p className='lcta__text'>Readlist is free and will be evolving. Join to experience and shape the solution.</p>
				<LoginButton handleLogin={props.handleLogin} cssClass='lcta__cta' />
			</div>
		</div>

		<div className='lfooter'>
			<div className='lfooter__container'>
				<div className='lfooter__links'>
					<a className='lfooter__link' href="https://twitter.com/ReadlistCom" target="_blank" rel='noopener noreferrer'>twitter</a>
					<span className='lfooter__divider'>·</span>
					<a href="https://twitter.com/anges244" title='product development' target="_blank" rel='noopener noreferrer' className='lfooter__link'>agesoulis</a>
					<span className='lfooter__divider'>·</span>
					<a href="https://twitter.com/ninalimpi" title='illustration and icons' target="_blank" rel='noopener noreferrer' className='lfooter__link'>ninalimpi</a>
					{/*<span className='lfooter__link'>terms</span>
					<span className='lfooter__divider'>·</span>
					<span className='lfooter__link'>privacy</span>*/}
				</div>
				<div className='lfooter__other'>
					<span>©2020 aggelos gesoulis - all rights reserved</span>
				</div>
			</div>
		</div>
	</Fragment>
	)
}