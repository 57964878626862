import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as CompletedIcon } from '../icons/completed.svg';
import { ReactComponent as RefreshIcon } from '../icons/refresh.svg';
import { ReactComponent as TelegramIcon } from '../icons/telegram_circle.svg';

export default function Telegram(props){
	const { connect_code, telegramId, checkIntegrations } = props;

	if (telegramId){
		return(
			<div className='page'>
				<div className='feedoptions'>
					<div className='feedoptions__main'></div>
					<div className='feedoptions__secondary'>
						<span className='feedoptions__item'>telegram</span>
						<Link to='/sources'><span className='feedoptions__item feedoptions__item--inactive'>sources</span></Link>
					</div>
				</div>
				<div className='page page--empty'>
					<div className='page__icon page__icon--success'>
						<CompletedIcon className='page__icon__svg page__icon__svg' />
					</div>
					<h3>Your Telegram account is successfully connected!</h3>
					<p>To add your links directly from Telegram, just send them to the <a href='https://telegram.me/getReadlistBot' rel='noreferrer noopener' target="_blank" className='inline__link'>ReadlistBot</a>.</p>
					<div className='center'>
						<Link to='/sources'>
							<span className='linked'>return to your sources</span>
						</Link>
					</div>
				</div>
			</div>	
		)
	}

	return (
		<div className='page'>
			<div className='feedoptions'>
				<div className='feedoptions__main'></div>
				<div className='feedoptions__secondary'>
					<span className='feedoptions__item'>telegram</span>
					<Link to='/sources'><span className='feedoptions__item feedoptions__item--inactive'>sources</span></Link>
				</div>
			</div>
			<div className='page page--empty'>
				<div className='page__icon'>
					<TelegramIcon className='page__icon__svg' />
				</div>
				<h3>Connect your Telegram account</h3>
				<p>To be able to add links directly from Telegram, copy the phrase below and send it to the <a href='https://telegram.me/getReadlistBot' rel='noreferrer noopener' target="_blank" className='inline__link'>ReadlistBot</a>:</p>
				<div className='center'>
					<p className='sources__code'>/connect {connect_code}</p>
				</div>
				<p>Check your status: 
					<span className='sources__iconBtn' onClick={checkIntegrations}>
						<RefreshIcon className='sources__icon' title="Check your Telegram integration status" />
					</span>
				</p>
			</div>
		</div>	
	);
}