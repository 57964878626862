import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { ReactComponent as MenuIcon } from '../icons/menu.svg';
import { ReactComponent as AddIcon } from '../icons/add.svg';

function Nav(props){
	return (
		<nav className='nav'>
			<div className='nav__container'>
				<Link to='/app'>
					<span className='nav__logo'>readlist</span>
				</Link>
				<div className='nav__menu'>
					{
						props.location.pathname && props.location.pathname === '/app'
							? (
								<Link to='/add'><span className='nav__menu__item'>
									<AddIcon className='nav__menu__item__icon' alt='menu-icon' title='Add new link' />
								</span></Link>
							)
							: null
					}
					<span className='nav__menu__item'>
						<MenuIcon className='nav__menu__item__icon' alt='menu-icon' title='Open menu'
							onClick={props.openSidebar}
						 />
					</span>
				</div>
			</div>
		</nav>		
	);
}

export default withRouter(Nav);