import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ReactComponent as ReadIcon } from '../icons/check2.svg';
import { ReactComponent as SnoozeIcon } from '../icons/snooze.svg';
import { ReactComponent as TrashIcon } from '../icons/trash.svg';
import { ReactComponent as MoreIcon } from '../icons/more.svg';
import { ReactComponent as TwitterIcon } from '../icons/twitter_logo.svg';
import Loader from './util/Loader';
const NO_IMG = "https://e3485408d64a637cbd49-c551eccdad875ba15f6c1cd78606ec94.ssl.cf5.rackcdn.com/temp/no_image.png";

class Unread extends Component{
	constructor(props){
		super(props);
		this.state = {
			activeLink: 0
		};
	}

	activateLink = (idx) => {
		this.setState({ activeLink: idx});
	}

	handleDeletion = () => {
		const { links, deleteLink } = this.props;
		const { activeLink} = this.state;
		const link = links[activeLink];
		deleteLink(link._id);
		this.setState({activeLink: 0});
	}

	handleSnooze = () => {
		const { links, snoozeLink } = this.props;
		const { activeLink} = this.state;
		const link = links[activeLink];
		snoozeLink(link._id);
		this.setState({activeLink: 0});
	}

	handleRead = () => {
		const { links, readLink } = this.props;
		const { activeLink} = this.state;
		const link = links[activeLink];
		readLink(link);
		this.setState({activeLink: 0});
	}

	confirmDeletion = () => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className='confirm'>
						<h3 className='confirm__title'>Are you sure?</h3>
						<p className='confirm__text'>This link won't be available in your history</p>
						<button className='confirm__button' onClick={onClose}>no</button>
						<button
							className='confirm__button confirm__button--main'
							onClick={() => {
								this.handleDeletion();
								onClose();
							}}
						>yes</button>
					</div>
				);
			}
		});
	};

	render(){
		const { loadingInitial, links, hasMore, is_new } = this.props;
		const { activeLink } = this.state;

		// Loading
		if (loadingInitial){ return	<Loader /> }


		if (links.length === 0){
			if (is_new){
				return(
				<div className='page'>
					<div className='feedoptions'>
						<div className='feedoptions__main'></div>
						<div className='feedoptions__secondary'>
							<span className='feedoptions__item'>unread</span>
							<Link to='/history'><span className='feedoptions__item feedoptions__item--inactive'>history</span></Link>
						</div>
					</div>
					<div className='page page--empty'>
						<h3>Welcome to Readlist!</h3>
						<p>Begin by sending a direct message with any link or tweet, to the <a href='https://twitter.com/ReadlistCom' target='_blank' rel='noopener noreferrer' className='linked'>Readlist Twitter</a> and check back after a few minutes.</p>
					</div>
				</div>
				)
			} else {
				return(
				<div className='page'>
					<div className='feedoptions'>
						<div className='feedoptions__main'></div>
						<div className='feedoptions__secondary'>
							<span className='feedoptions__item'>unread</span>
							<Link to='/history'><span className='feedoptions__item feedoptions__item--inactive'>history</span></Link>
						</div>
					</div>
					<div className='page page--empty'>
						<h3>No links waiting for you!</h3>
						<p>You can DM your links to the Readlist Twitter Account or connect any of the other sources available.</p>
					</div>
				</div>
				)
			}
		}

		return(
			<div className='page'>
				<div className='feedoptions'>
					<div className='feedoptions__main'></div>
					<div className='feedoptions__secondary'>
						<span className='feedoptions__item'>unread</span>
						<Link to='/history'><span className='feedoptions__item feedoptions__item--inactive'>history</span></Link>
					</div>
				</div>
				<div className='feed'> { links.map((link,idx) => (
					<div className='link' key={link._id}>
						<div className='link__container'>
							<div className='link__media' onClick={()=> this.activateLink(idx)}>
								<a href={link.data.url} target="_blank"  rel="noopener noreferrer">
									<div className='link__media__content'>
										<img src={link.data.img || NO_IMG}  className='link__media__content__img' 
											onError={(e)=>{e.target.onerror = null; e.target.src=NO_IMG}} alt=''
											/>
									</div>
								</a>
								{
									link.data_origin && link.data_origin.url
										? (
											<a href={link.data_origin.url} target="_blank"  rel="noopener noreferrer" title={link.data_origin.title} className='link__twitter'>
												<TwitterIcon className='link__twitter__icon'/>
											</a>
										)
										: null
								}
							</div>
							<a href={link.data.url} target="_blank"  rel="noopener noreferrer" onClick={()=> this.activateLink(idx)}>
								<div className='link__content'>
									<h3 className='link__title'>{link.data.title || "No preview title available..."}</h3>
									<p className='link__content__text'>{link.data.content || "No preview text available..."}</p>
								</div>
							</a>
							{
								activeLink === idx
									? (<div className='link__options'>
										<ReadIcon className='link__option link__option--main' title='Mark as read' onClick={this.handleRead} />
										<SnoozeIcon className='link__option' title='Snooze for later' onClick={this.handleSnooze} />
										<TrashIcon className='link__option' title='Delete' onClick={this.confirmDeletion} />
									</div>)
									: (<div className='link__options'>
										<MoreIcon className='link__option link__option--secondary' title='Activate menu'
											onClick={()=> this.activateLink(idx)}
										 />
									</div>)
							}
						</div>
					</div>
				))}</div>
				{
					hasMore
						? (
							null
						) : null
				}
			</div>
		)
	}
}

export default Unread;