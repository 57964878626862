import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as AddIcon } from '../icons/add_plain.svg';
import { ReactComponent as CompletedIcon } from '../icons/completed.svg';
import { addLink } from '../utils/api';
import Loader from './util/Loader';
import isURL from 'validator/lib/isURL';

class AddLink extends Component{
	constructor(props){
		super(props);

		this.state ={
			error: null,
			url: "",
			success: false,
			loading: false
		}

		this.timer = null;
	}

	handleChange = (e) => {
		this.setState({url: e.target.value});
	}

	handleSubmit = (e) => {
		const { url } = this.state;
		e.preventDefault();
		if (!isURL(url)){ return this.setState({error: 'Enter a valid link address'}, ()=> {
			this.timer = setTimeout(() => {
				this.setState({error: null})
			}, 5000);
		})}
		this.setState({loading: true});
		addLink(url)
		.then((data) => {
			this.setState({success: true, loading: false});
			this.props.addManualNewLink(data);
		})
		.catch((err) => {
			console.log(err.response);
			if (!err.response || !err.response.data){ err = 'Something went wrong, please try again'; }
			this.setState({ error: err.response.data, loading: false}, () => {
				this.timer = setTimeout(() => {
					this.setState({error: null})
				}, 5000);
			});
		})
	}

	componentWillUnmount(){
		if (this.timer){ clearTimeout(this.timer); }
	}

	render(){
		const { error, url, loading, success } = this.state;

		if (loading){
			return	<Loader text='Processing link' />
		}

		if (success){
			 return (
			 	<div className='page'>
					<div className='feedoptions'>
						<div className='feedoptions__main'></div>
						<div className='feedoptions__secondary'>
							<Link to='/app'><span className='feedoptions__item feedoptions__item--inactive'>return</span></Link>
						</div>
					</div>
					<div className='page page--empty'>
						<div className='page__icon page__icon--success'>
							<CompletedIcon className='page__icon__svg page__icon__svg' />
						</div>
						<h3>Your link has been successfully added!</h3>
						<div className='center'>
							<Link to='/app'>
								<span className='linked'>return to your readlist</span>
							</Link>
						</div>
					</div>
				</div>	
			 )
		}

		return(
			<div className='page'>
				<div className='feedoptions'>
					<div className='feedoptions__main'></div>
					<div className='feedoptions__secondary'>
						<Link to='/app'><span className='feedoptions__item feedoptions__item--inactive'>cancel</span></Link>
					</div>
				</div>
				<div className='page page--empty'>
					<h3>add a new link</h3>
					<form className='form' onSubmit={this.handleSubmit}>	
						<input type='text' className='form__input' placeholder='paste your url here'
							onChange={this.handleChange } value={url}/>
						<button type='submit' className='form__button'
							disabled={!url || url.length < 5}
						>
							<AddIcon className='form__button__icon' />
						</button>
					</form>
				</div>
				{
					!!error
						? (
							<div className='error'>
								<div className='error__inner'>{error}</div>
							</div>
						) : null
				}
			</div>
		)
	}
}

export default AddLink;