import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Loader from './util/Loader';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';

import { ReactComponent as RestoreIcon } from '../icons/restore.svg';
import { ReactComponent as TrashIcon } from '../icons/trash.svg';
import { ReactComponent as MoreIcon } from '../icons/more.svg';
import { ReactComponent as TwitterIcon } from '../icons/twitter_logo.svg';

const NO_IMG = "https://e3485408d64a637cbd49-c551eccdad875ba15f6c1cd78606ec94.ssl.cf5.rackcdn.com/temp/no_image.png";

class History extends Component{
	constructor(props){
		super(props)
		this.state = {
			activeLink: null
		}
	}

	activateLink = (idx) =>	this.setState({ activeLink: idx});


	handleRestore = () => {
		const { links, restoreLink } = this.props;
		const { activeLink} = this.state;
		const link = links[activeLink];
		restoreLink(link);
		this.setState({activeLink: 0});
	}

	handleDeletion = () => {
		const { links, deleteLink } = this.props;
		const { activeLink} = this.state;
		const link = links[activeLink];
		deleteLink(link._id);
		this.setState({activeLink: 0});
	}

	handleLoadMore = () => {
		const { loadMore } = this.props;
		loadMore();
		this.setState({activeLink: null});
	}

	confirmDeletion = () => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className='confirm'>
						<h3 className='confirm__title'>Are you sure?</h3>
						<p className='confirm__text'>This link will be permanently removed</p>
						<button className='confirm__button' onClick={onClose}>no</button>
						<button
							className='confirm__button confirm__button--main'
							onClick={() => {
								this.handleDeletion();
								onClose();
							}}
						>yes</button>
					</div>
				);
			}
		});
	};

	render(){
		const { activeLink } = this.state;
		const { links, loading, hasMore } = this.props; 

		if (loading){
			return(
				<div className='page'>
					<div className='feedoptions'>
						<div className='feedoptions__main'></div>
						<div className='feedoptions__secondary'>
							<Link to='/app'><span className='feedoptions__item  feedoptions__item--inactive'>unread</span></Link>
							<span className='feedoptions__item'>history</span>
						</div>
					</div>
					<Loader />
				</div>
			)
		}
		
		if (links.length < 1){
			return(
				<div className='page'>
					<div className='feedoptions'>
						<div className='feedoptions__main'></div>
						<div className='feedoptions__secondary'>
							<Link to='/app'><span className='feedoptions__item  feedoptions__item--inactive'>unread</span></Link>
							<span className='feedoptions__item'>history</span>
						</div>
					</div>
						<div className='page page--empty'>
						<h3>Nothing added to your history yet!</h3>
						<p>Every link you've marked as read, will appear here, if you need to revisit it.</p>
					</div>
				</div>
			)
		} 

		return(
			<div className='page'>
				<div className='feedoptions'>
					<div className='feedoptions__main'></div>
					<div className='feedoptions__secondary'>
						<Link to='/app'><span className='feedoptions__item  feedoptions__item--inactive'>unread</span></Link>
						<span className='feedoptions__item'>history</span>
					</div>
				</div>
				<div className='feed'> { links.map((link,idx) => (
					<div className='link' key={link._id}>
						<div className='link__container'>
							<div className='link__media' onClick={()=> this.activateLink(idx)}>
								<a href={link.data.url} target="_blank"  rel="noopener noreferrer">
									<div className='link__media__content'>
										<img src={link.data.img || NO_IMG}  className='link__media__content__img' 
											onError={(e)=>{e.target.onerror = null; e.target.src=NO_IMG}} alt='' />
									</div>
								</a>
								{
									link.data_origin && link.data_origin.url
										? (
											<a href={link.data_origin.url} target="_blank"  rel="noopener noreferrer" title={link.data_origin.title} className='link__twitter'>
												<TwitterIcon className='link__twitter__icon'/>
											</a>
										)
										: null
								}
							</div>
							<a href={link.data.url} target="_blank"  rel="noopener noreferrer" onClick={()=> this.activateLink(idx)}>
								<div className='link__content'>
									<h3 className='link__title'>{link.data.title || "No preview title available..."}</h3>
									<p className='link__content__text'>{link.data.content || "No preview text available..."}</p>
								</div>
							</a>
							{
								activeLink === idx
									? (<div className='link__options'>
										<RestoreIcon className='link__option' title='Restore to your Readlist' onClick={this.handleRestore} />
										<TrashIcon className='link__option' title='Delete' onClick={this.confirmDeletion} />
									</div>)
									: (<div className='link__options'>
										<MoreIcon className='link__option link__option--secondary' title='Activate menu'
											onClick={()=> this.activateLink(idx)}
										 />
									</div>)
							}
						</div>
					</div>
				))}
				{
					hasMore
						? (
							<div className='loadmore__wrapper'>
								<button className='loadmore' onClick={this.handleLoadMore}>Load more</button>
							</div>
						)
						: null
				}
				</div>
			</div>
		)
	}
}

export default History;