import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as TwitterIcon } from '../icons/twitter_logo.svg';
import { ReactComponent as CompletedIcon } from '../icons/completed.svg';
import { ReactComponent as TelegramIcon } from '../icons/telegram.svg';
import { ReactComponent as AddIcon } from '../icons/add_plain.svg';

export default function Sources(props){
	return(
		<div className='page'>
			<div className='feedoptions'>
				<div className='feedoptions__main'></div>
				<div className='feedoptions__secondary'>
					<span className='feedoptions__item'>sources</span>
				</div>
			</div>
			<div className='sources'>
				<div className='source'>
					<div className='source__media'>
						<div className='source__media__content source--twitter'>
							<TwitterIcon className='source__media__icon' />
						</div>
					</div>
					<div className='source__content'>
						<h3 className='source__title'>Twitter</h3>
						<p className='source__text'>Send any tweet or link, with a DM to the <a href='https://twitter.com/ReadlistCom' target="_blank" rel="noopener noreferrer">Readlist Twitter</a> to automatically add it.</p>
					</div>
					<div className='source__options'>
						<CompletedIcon className='source__option source__option--success' title="Your Twitter integration has been activated" />
					</div>
				</div>

				<div className='source'>
					<div className='source__media'>
						<div className='source__media__content source--telegram'>
							<TelegramIcon className='source__media__icon' />
						</div>
					</div>
					<div className='source__content'>
						<h3 className='source__title'>Telegram</h3>
						<p className='source__text'>Connect your Telegram account to send your links to the <a href='https://telegram.me/getReadlistBot' rel='noreferrer noopener' target="_blank">Readlist Telegram Bot</a>.</p>
					</div>
					<div className='source__options'>
						{
							props.telegramId 
								? (
									<CompletedIcon className='source__option source__option--success' title="Your Telegram account has been connected" />
								)
								:(
									<Link to='/sources/telegram'>
										<AddIcon className='source__option source__option--normal' title="Connect your Telegram account" />
									</Link>
								)
						}
					</div>
				</div>

			</div>
		</div>
	)
}