import axios from 'axios';
import { getToken, destroyToken } from './authService';

//export const API_URL = 'http://localhost:4000';
export const API_URL = 'https://api.getreadlist.com';

axios.interceptors.response.use(response => {
	return response;
}, error => {
	if (error.response.status === 401) {
		destroyToken();
		return window.location.reload(true); 
	}
	return error;
});

const genAxiosConfig = () => {
	return { headers: { authorization: getToken()}}
};

export function getInitialData(){
	return axios.get(`${API_URL}/v1/initial_data`, genAxiosConfig())
		.then((response) => response.data);
};

export function linkRead(linkId, lastLinkId){
	return axios.post(`${API_URL}/v1/link_read`, {
		linkId: linkId,
		lastLinkId: lastLinkId
	},genAxiosConfig()).then((response) => response.data );
};

export function linkSnooze(linkId, lastLinkId){
	return axios.post(`${API_URL}/v1/link_snooze`, {
		linkId: linkId,
		lastLinkId: lastLinkId
	},genAxiosConfig()).then((response) => response.data );
};

export function linkDelete(linkId, lastLinkId){
	return axios.post(`${API_URL}/v1/link_delete`, {
		linkId: linkId,
		lastLinkId: lastLinkId
	},genAxiosConfig()).then((response) => response.data );
};

export function getHistory(lastLinkId){
	return axios.post(`${API_URL}/v1/history`, {
		lastLinkId: lastLinkId
	},genAxiosConfig()).then((response) => response.data );
};

export function linkRestore(linkId){
	return axios.post(`${API_URL}/v1/link_restore`, {
		linkId,
	},genAxiosConfig()).then((response) => response.data );
};

export function getIntegrations(){
	return axios.get(`${API_URL}/v1/integrations_get`, genAxiosConfig())
		.then((response) => response.data);
};

export function addLink(linkUrl,){
	return axios.post(`${API_URL}/v1/link_add`, {
		linkUrl: linkUrl,
	},genAxiosConfig()).then((response) => response.data );
};

export function deleteUser(connect_code,){
	return axios.post(`${API_URL}/v1/user_delete`, {
		connect_code: connect_code,
	},genAxiosConfig()).then((response) => response.data );
};


