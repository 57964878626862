const tokenName = 'jwtReadToken';

export function getToken(){	
	try {
		const tokens = localStorage.getItem(tokenName) ;
		if (tokens !== null) {
			return tokens;
		}
		return null;
	} catch (e) {
		return null;
	}
}

export function setToken(token){
	try {
		if (localStorage !== null) {
			return localStorage.setItem(tokenName, token);
		}
		return null;
	} catch (e) {
		return null;
	}
}

export function destroyToken(){	
	try {
		if (localStorage !== null) {
			return localStorage.removeItem(tokenName); 
		}
		return null;
	} catch (e) {
		return null;
	}

}
/*
export function getToken(){	return localStorage.getItem(tokenName); }

export function setToken(token){ return localStorage.setItem(tokenName, token); }

export function destroyToken(){	return localStorage.removeItem(tokenName); } */