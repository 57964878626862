import React from 'react';
import Spinner from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


export default function Loader(props){
	return (
		<div className='page'>
			<div className='page page--empty center'>
					<Spinner
						type="Triangle"
						color="#151516"
						height={64}
						width={64}
						className='loader__icon'
					/>
					<p className='loader__text'>{props.text || "Loading"}</p>
				</div>
			
		</div>	
	);
}