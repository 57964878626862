import React, { Fragment } from 'react';
import TwitterLogin from 'react-twitter-auth/lib/react-twitter-auth-component.js';
import { API_URL } from '../../utils/api';
import { useHistory, useLocation } from 'react-router-dom';
import { getToken } from '../../utils/authService';
import {publicRoutes} from '../../utils/publicRoutes';

const publicPaths = publicRoutes.map((r) => r['path']);

export default function LoginButton(props){
	let history = useHistory();
	let location = useLocation();
	let { from } = location.state || { from: { pathname: "/" } };
	const isAuthenticated = !!getToken();

	const handleLoginFailure = (err) => {
		console.log(err);
		return props.handleLogin(null);
	};

	const handleLoginSuccess = (response) => {
		const token = response.headers.get('x-auth-token');
		props.handleLogin(token);
		if (from.pathname && publicPaths.includes(from.pathname)) {
			return history.replace('/app');
		}
		return history.replace(from);
	};

	const handleMockLogin = () => {
		if (from.pathname && publicPaths.includes(from.pathname)) {
			return history.replace('/app');
		}
		return history.replace(from);
	}

	return(
		<Fragment>
			{
				!isAuthenticated
					? <TwitterLogin
						loginUrl={`${API_URL}/auth/twitter`}
						onFailure={handleLoginFailure}
						onSuccess={handleLoginSuccess}
						showIcon={false}
						requestTokenUrl={`${API_URL}/auth/twitter/reverse`}
						className={props.cssClass || 'button'}
						text='Enter with Twitter'
					/>
					: <button className={props.cssClass} onClick={handleMockLogin}>Enter with Twitter</button>
			}
			
		</Fragment>
	)
}