import React, { Component, Fragment } from 'react';
import Sidebar from "react-sidebar";
import Nav from './Nav';
import SidebarContent from './SidebarContent';
import PrivateRoute from './util/PrivateRoute';
import Unread from './Unread';
import History from './History';
import Settings from './Settings';
import Sources from './Sources';
import Telegram from './Telegram';
import AddLink from './AddLink';
import { getInitialData, linkDelete, linkSnooze, linkRead, getHistory,
	linkRestore, getIntegrations, deleteUser } from '../utils/api';

class App extends Component {
	constructor(props){
		super(props);

		this.state={
			sidebarOpen: false,
			user: {},
			loadingInitial: true,
			links: [],
			history: [],
			history_error: false,
			hasMoreHistory: false,
			loadingHistory: false,
			server_error: false,
			is_new: false
		}
	}

	componentDidMount(){
		const { user } = this.state;
		if (!Object.getOwnPropertyNames(user).length){
			getInitialData()
				.then((data) => {
					this.setState(() => ({
						user: data.user,
						links: data.links,
						loadingInitial: false,
						hasMoreHistory: data.hasMore,
						hasMore: data.hasMoreInit,
						history: data.history,
						telegramId: data.user.telegramId || null,
						connect_code: data.user.connect_code || null,
						is_new: data.user.is_new || null
					}))
				})
				.catch((err) => {
					console.log(err);
					this.setState({server_error: 'Could not reach the server'})
				});
		}
	}

	onSetSidebarOpen = (newValue) => this.setState({ sidebarOpen: newValue })

	readLink = (link) => {
		const { links, history } = this.state;
		const newLinks = links.filter((l) => link._id !== l._id);
		link.read = new Date();
		const newHistory = [link, ...history ];
		this.setState({links: newLinks, history: newHistory}, () => {
			const lastLinkId = newLinks.length ? newLinks[newLinks.length - 1]._id : null;
			// Make the call
			linkRead(link._id, lastLinkId)
				.then((data) => {
					if (data.link && data.link._id){
						this.setState({
							links: newLinks.concat([data.link])
						});
					}
				})
				.catch((err) => {
					console.log('Error occured while marking as read');
					this.setState({links, history});
				})
		});
	}

	snoozeLink = (linkId) => {
		const { links } = this.state;
		const newLinks = links.filter((l) => linkId !== l._id);
		this.setState({links: newLinks}, () => {
			const lastLinkId = newLinks.length ? newLinks[newLinks.length - 1]._id : null;
			// Make the call
			linkSnooze(linkId, lastLinkId)
				.then((data) => {
					if (data.link && data.link._id){
						this.setState({
							links: newLinks.concat([data.link])
						});
					}
				})
				.catch((err) => {
					console.log('Error occured while snoozing the link');
					this.setState({links});
				})
		});
	}

	deleteLink = (linkId) => {
		const { links } = this.state;
		const newLinks = links.filter((l) => linkId !== l._id);
		this.setState({links: newLinks}, () => {
			const lastLinkId = newLinks.length ? newLinks[newLinks.length - 1]._id : null;
			// Make the call
			linkDelete(linkId, lastLinkId)
				.then((data) => {
					if (data.link && data.link._id){
						this.setState({
							links: newLinks.concat([data.link])
						});
					}
				})
				.catch((err) => {
					console.log('Error occured while deleting');
					this.setState({links});
				})
		});
	}

	addRestoredToUnread = (link) => {
		const { links } = this.state;
		const newLinks = links.concat([link]);
		const finalLinks = [...newLinks].sort((a, b) => {
			if (a._id < b._id) return -1;
			if (a._id > b._id) return 1;
			return 0;
		});
		this.setState({links: finalLinks});
	}

	restoreLink = (link) => {
		const { history } = this.state;
		const newHistory = history.filter((l) => link._id !== l._id);
		this.setState({history: newHistory}, () => {
			// Make the call
			linkRestore(link._id)
				.then((data) => {
					// Add to readlist if needed
					this.addRestoredToUnread(link);
				})
				.catch((err) => {
					console.log('Error occured while snoozing the link');
					this.setState({history});
				})
		});
	}

	deleteHistoryLink = (linkId) => {
		const { history } = this.state;
		const newLinks = history.filter((l) => linkId !== l._id);
		this.setState({history: newLinks}, () => {
			// Make the call
			linkDelete(linkId, null)
				.then((data) => {})
				.catch((err) => {
					console.log('Error occured while deleting item from history');
					this.setState({history});
				})
		});
	}

	loadHistory = () => {
		const {history} = this.state;
		this.setState({loadingHistory: true});
		getHistory(history.length > 0 ? history[history.length - 1]._id : null)
		.then((data) => {
			this.setState(() => ({
				history: data.links,
				loadingHistory: false,
				hasMoreHistory: data.hasMore
			}))
		})
		.catch((err) => {
			console.log(err);
			this.setState({error: 'Could not retrieve your history'});
		});
	}

	loadMoreHistory = () => {
		const {history} = this.state;
		if (!history || history.length < 1){ return; }
		getHistory(history[history.length - 1].read)
		.then((data) => {
			let newLinks = [];
			if (data.links && data.links.length > 0){
				newLinks = newLinks.concat(history);
				newLinks = newLinks.concat(data.links)
			}
			this.setState(() => ({
				history: newLinks,
				loadingHistory: false,
				hasMoreHistory: data.hasMore
			}))
		})
		.catch((err) => {
			console.log(err);
			this.setState({error: 'Could not retrieve your history'});
		});
	}

	checkIntegrations = () => {
		getIntegrations()
		.then((data) => {
			this.setState(() => ({
				telegramId: data.telegramId
			}))
		})
		.catch((err) => {
			console.log(err);
			this.setState({error: 'Could not check the integrations'});
		});
	}

	addManualNewLink = (link) => {
		const { links } = this.state;
		this.setState({links: links.concat([link])})
	}

	deleteUser = () =>{		
		deleteUser(this.state.connect_code)
			.then((data) => {
				this.props.handleLogout();
			})
			.catch((err) => {
				alert('Something went wrong, please try again.');
			});
	}

	render(){		
		const { loadingInitial, links, history, history_error, hasMoreHistory, 
			loadingHistory, connect_code, telegramId, hasMore, is_new } = this.state;

		return(
			<Fragment>
				<Sidebar
					sidebar={
						<SidebarContent 
							closeSidebar={this.onSetSidebarOpen} 
							handleLogout={this.props.handleLogout}
						/>
					}
					open={this.state.sidebarOpen}
					onSetOpen={this.onSetSidebarOpen}
					styles={{ sidebar: { background: "#161617" } }}
					pullRight={true}
					shadow={false}
					overlayClassName="side__overlay"
					sidebarClassName="side_class"
				>
					<Nav openSidebar={ () => {this.onSetSidebarOpen(true)}}/>
					<PrivateRoute path="/app" exact>
						<Unread
							loadingInitial={loadingInitial}
							links={links}
							readLink={this.readLink}
							snoozeLink={this.snoozeLink}
							deleteLink={this.deleteLink}
							hasMore={hasMore}
							is_new={is_new}
						 />
					</PrivateRoute>

					<PrivateRoute path="/history" exact>
						<History addRestoredToList={this.addRestoredToList}
							loadHistory={this.loadHistory}
							links={history}
							hasMore={hasMoreHistory}
							loading={loadingHistory}
							error={history_error}
							restoreLink={this.restoreLink}
							deleteLink={this.deleteHistoryLink}
							loadMore={this.loadMoreHistory}
						/>
					</PrivateRoute>

					<PrivateRoute path="/add" exact>
						<AddLink addManualNewLink={this.addManualNewLink}/>
					</PrivateRoute>

					<PrivateRoute path="/settings" exact>
						<Settings deleteUser={this.deleteUser} />
					</PrivateRoute>

					<PrivateRoute path="/sources" exact>
						<Sources  telegramId={telegramId} />
					</PrivateRoute>

					<PrivateRoute path="/sources/telegram" exact>
						<Telegram  telegramId={telegramId} connect_code={connect_code}
							checkIntegrations={this.checkIntegrations}
						/>
					</PrivateRoute>

				</Sidebar>
			</Fragment>
		)
	}
}

export default App;


/*
const { handleLogout } = this.props;

return (
			<div>
				This is the app, you logged-in hottie!<br /><br />
				<div>
					<button onClick={handleLogout}>Log out</button>
				</div>
			</div>
		);
*/