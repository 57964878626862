import React from 'react';
//import { ReactComponent as RefreshIcon } from '../icons/refresh.svg';
import { ReactComponent as CloseIcon } from '../icons/close.svg';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';

export default function Settings(props){
	const confirmDeletion = () => {
		confirmAlert({
			customUI: ({ onClose }) => {
				return (
					<div className='confirm'>
						<h3 className='confirm__title'>Are you sure?</h3>
						<p className='confirm__text'>Your links and history will also be deleted permanently.</p>
						<button className='confirm__button' onClick={onClose}>no</button>
						<button
							className='confirm__button confirm__button--main confirm__button--error'
							onClick={() => {
								props.deleteUser();
								onClose();
							}}
						>yes</button>
					</div>
				);
			}
		});
	};

	return(
		<div className='page'>
			<div className='feedoptions'>
				<div className='feedoptions__main'></div>
				<div className='feedoptions__secondary'>
					<span className='feedoptions__item'>settings</span>
				</div>
			</div>
			{/*<div className='settings__column'>
				<div className='settings'>
					<div className='settings__content'>
						<h3 className='settings__title'>Profile Details</h3>
						<p className='settings__text'>Sync your Twitter profile to grab your new data.</p>
					</div>
					<div className='settings__options'>
						<span className='settings__iconBtn'>
							<RefreshIcon className='settings__icon' title="Sync your Twitter" />
						</span>
					</div>
				</div>
			</div>*/}


			<div className='settings__column'>
				<div className='settings'>
					<div className='settings__content'>
						<h3 className='settings__title'>Delete account</h3>
						<p className='settings__text'>When it's time, it's time.</p>
					</div>
					<div className='settings__options'>
						<span className='settings__iconBtn'>
							<CloseIcon onClick={confirmDeletion} className='settings__icon' title="Proceed to delete your account" />
						</span>
					</div>
				</div>
			</div>

		</div>
	)
}