import React, { Component, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { getToken, destroyToken, setToken } from '../utils/authService';
import App from './App';
/* eslint-disable no-unused-vars */
import Landing from './Landing';
/* eslint-enable no-unused-vars */
import {publicRoutes} from '../utils/publicRoutes';

class AppWrapper extends Component {
	constructor(props){
		super(props);

		this.state = {
			isAuthenticated: getToken(),
			loading: false
		}
	}

	handleLogin = (token) => {
		setToken(token);
		return this.setState({isAuthenticated: token});
	}

	handleLogout = () => {
		destroyToken();
		return this.setState({isAuthenticated: false});
	}

	render(){
		return(
			<Router>
				<Fragment>
					<Switch>
						{
							publicRoutes.map((route,idx) => {
								const CurrentComponent = publicRoutes[idx].component;
								return <Route path={route.path} exact key={route.path} render={(props) => <CurrentComponent {...props} handleLogin={this.handleLogin} />} />
							})
						}
						<App 
							isAuthenticated={this.state.isAuthenticated}
							handleLogout={this.handleLogout}
						/>
					</Switch>
				</Fragment>
			</Router>
		)
	}
}

export default AppWrapper;
