import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as CloseIcon } from '../icons/close.svg';
import { ReactComponent as LogoutIcon } from '../icons/logout.svg';
import { ReactComponent as ShareIcon } from '../icons/share.svg';
import { ReactComponent as SettingsIcon } from '../icons/settings2.svg';
import { ReactComponent as AddIcon } from '../icons/add_plain.svg';

const LinkWrapper = (props) => {
	return(
		<div onClick={() => props.closeSidebar(false)}>
			<Link to={props.to}>
				{props.children}
			</Link>
		</div>
	)
}

export default function SidebarContent(props){
	return (
		<div className='sidebar'>
			<div className='sidebar__header'>
				<CloseIcon className='sidebar__close' onClick={() => props.closeSidebar(false)} />
			</div>
			<div className='sidebar__content'>
				<div className='sidebar__container'>
					<div className='sidebar__item'><LinkWrapper to='/sources' closeSidebar={props.closeSidebar}>
						<ShareIcon className='sidebar__item__icon' />
						<span className='sidebar__item__text'>Sources</span>
					</LinkWrapper></div>
					<div className='sidebar__item'><LinkWrapper to='/settings' closeSidebar={props.closeSidebar}>
						<SettingsIcon className='sidebar__item__icon' />
						<span className='sidebar__item__text'>Settings</span>
					</LinkWrapper></div>
					<div className='sidebar__item' onClick={ props.handleLogout }>
						<LogoutIcon className='sidebar__item__icon' />
						<span className='sidebar__item__text'>Log out</span>
					</div>
				</div>
			</div>
			<div className='sidebar__footer'>
				<div className='sidebar__footer__container'>
					<LinkWrapper to='/add' closeSidebar={props.closeSidebar}>
						<button className='sidebar__button'>
							<AddIcon className='sidebar__button__icon' />
							<span>Add link</span>
					</button>
					</LinkWrapper>
					<div className='sidebar__footer__links'>
						<a href='https://twitter.com/ReadlistCom' target='_blank' rel='noreferrer noopener'>twitter</a> 
						<span className='sidebar__footer__divider'>·</span>
						<a href='https://telegram.me/getReadlistBot' target='_blank' rel='noreferrer noopener'>telegram</a> 
						<span className='sidebar__footer__divider'>·</span>
						<a href='https://twitter.com/anges244' target='_blank' rel='noreferrer noopener'>contact</a>
					</div>
				</div>
			</div>
		</div>	
	);
}